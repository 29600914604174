.carousel {
  position: relative;
  .carousel-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    .carousel-item {
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &:after {
    content: "";
    padding-bottom: 40%;
    display: block;
  }
}
