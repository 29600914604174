@import '~bootstrap/scss/functions';
@import '../../assets/stylesheets/custom_variables.scss';

.ticket-card {
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  padding-bottom: 1.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;

  &.card-right {
    @media (min-width: 768px) {
      margin-left: 2rem;
    }
    @media (max-width: 767px) {
      .mb-xs-4 {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.ticket-false-label {
  color: $gray-500;
  margin-top: 5px;
  margin-bottom: 11px;
}

.ticket-show-eye {
  color: $info;
  &:hover {
    cursor: pointer;
  }
}
