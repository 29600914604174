@import '~bootstrap/scss/functions';

.custom-data-table {
  .rdt_TableCell {
    padding-right: 10px;
    padding-left: 10px;
    overflow-y: hidden;

    div {
      white-space: normal !important;

      span {
        white-space: normal !important;
      }
    }
  }
}
